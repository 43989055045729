import { useNavigate } from "react-router-dom";

const Logout = () => {
  const navigate = useNavigate();
  localStorage.removeItem("user_session");
  sessionStorage.clear();
  localStorage.clear();

  // Set a 'logout' flag in localStorage
  localStorage.setItem("logout", Date.now());
  localStorage.removeItem("setupTime");
  localStorage.removeItem("user");
  navigate("/");
  window.location.reload();
};

export default Logout;
