import React, { useState, useEffect } from "react";
import axios from "axios";
import { Button, Form, Input, Select, message, Row, Col } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import AppBar from "@mui/material/AppBar";
import List from "@mui/material/List";
import Grid from "@mui/material/Grid";
const EditSimCard = ({ setOpen, id }) => {
  const mobileoOperatorsApi = process.env.REACT_APP_API_MobileOperators;
  const countriesApi = process.env.REACT_APP_API_Countries;
  const simCardsApi = process.env.REACT_APP_API_SIMCards;
  const [countryList, setCountryList] = useState();
  const [apiData, setApiData] = useState(null);
  const [messageApi, contextHolder] = message.useMessage();
  const [country, setCountry] = useState("");
  const [countryObject, setCountryObject] = useState("");
  const [mnoName, setMnoName] = useState("");
  const [mnoList, setMnoList] = useState();
  const [diallingCode, setDiallingCode] = useState("");
  const [msisdn, setMsisdn] = useState("");
  const [isModified, setIsModified] = useState(false);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [mnoData, setMnoData] = useState(null);
  const { Option } = Select;

  useEffect(() => {
    const dataUrl = countriesApi;
    fetch(dataUrl)
      .then((res) => res.json())
      .then((res) => {
        setCountryList(res.body);
      });
  }, []);
  useEffect(() => {
    const dataUrl = `${simCardsApi}/${id}`;
    fetch(dataUrl)
      .then((res) => res.json())
      .then((res) => {
        setApiData(res.body);
      });
    axios
      .get(`${mobileoOperatorsApi}/${mnoName}`)
      .then((response) => {
        setMnoData(response.data.body);
      })
      .catch((error) => {
        console.error("Error fetching mobile operator data:", error);
      });
  }, [id, mnoName]);

  useEffect(() => {
    if (apiData) {
      setCountry(apiData.country);
      setMnoName(apiData.mno_id);
      if (countryList && apiData) {
        const countryId = mnoData.country_id;
        const mnoCountry = countryList.find(
          (country) => country.id === countryId
        );
        if (mnoCountry) {
          setCountry(mnoCountry.name);
          setDiallingCode(mnoCountry.dialing_code);
        }
      }
      setDiallingCode(apiData.dialing_code);
      setMsisdn(apiData.msisdn);
    }
  }, [apiData, setApiData, countryList, setCountryList]);

  const updateAllFields = async () => {
    const updateUrl = `${simCardsApi}/${id}`;
    const updatedData = {
      mno_id: mnoName,
      msisdn: msisdn,
    };

    try {
      const response = await fetch(updateUrl, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(updatedData),
      });

      if (response.ok) {
        messageApi.success("Data updated successfully");
        setOpen(false);
        setIsModified(false);
      } else {
        console.error("Failed to update data");
        messageApi.error("Failed to update data");
      }
    } catch (error) {
      console.error("Error updating data:", error);
    }
  };

  return (
    <>
      <AppBar sx={{ position: "relative" }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={() => setOpen(false)}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            Edit SIM Card
          </Typography>
        </Toolbar>
      </AppBar>
      <List style={{ height: "100vh" }}>
        <Grid
          container
          style={{ height: "100%", justifyContent: "center" }}
          alignItems="center"
        >
          <Grid item xs={12} sm={8} md={6} lg={8}>
            <Form
              form={form}
              layout="vertical"
              className="ml-10 mr-10 pl-10 pt-10 pr-10 pb-4 bg-[#FFFFFF]"
            >
              <div className=" mb-4">
                <h2 className="text-2xl font-bold">Edit SIM Card</h2>
              </div>
              <div className="grid grid-cols-1 gap-4">
                <Row gutter={16}>
                  <Col xs={{ span: 24 }} lg={{ span: 8 }}>
                    <Form.Item
                      label="Country"
                      required
                      tooltip={{
                        title: "This is a required field",
                        icon: <InfoCircleOutlined />,
                      }}
                    >
                      <Select
                        showSearch
                        placeholder="Select your country"
                        value={country}
                        onChange={(value) => {
                          const dataUrl = `${countriesApi}/${value}`;
                          fetch(dataUrl)
                            .then((res) => res.json())
                            .then((res) => {
                              setCountryObject(res.body);
                              setCountry(res.body.name);
                              setDiallingCode(res.body.dialing_code);
                              const dataUrl = `${mobileoOperatorsApi}?country=${value}`;
                              fetch(dataUrl)
                                .then((res) => res.json())
                                .then((res) => {
                                  setMnoList(res.body);
                                });
                            });
                          setMnoName("");
                          setIsModified(true);
                          // setDialingCode('');
                          // Reset dialing code when the country changes
                        }}
                        style={{ width: "100%" }}
                      >
                        {countryList?.map((country) => (
                          <Option key={country?.id} value={country?.id}>
                            {country?.name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col xs={{ span: 24 }} lg={{ span: 8 }}>
                    <Form.Item
                      label="MNO Name"
                      required
                      tooltip={{
                        title: "This is a required field",
                        icon: <InfoCircleOutlined />,
                      }}
                    >
                      <Select
                        showSearch
                        placeholder="Select your MNO Name"
                        value={mnoName}
                        onChange={(value) => {
                          setMnoName(value);
                          setIsModified(true);
                        }}
                        style={{ width: "100%" }}
                        disabled={!country} // Disable when no country is selected
                      >
                        {mnoList?.map((mno) => (
                          <Option key={mno.id} value={mno.id}>
                            {mno.name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col xs={{ span: 24 }} lg={{ span: 8 }}>
                    <Form.Item
                      label="Dialing Code"
                      required
                      tooltip={{
                        title: "This is a required field",
                        icon: <InfoCircleOutlined />,
                      }}
                    >
                      <Select
                        showSearch
                        placeholder="Select your Dialing Code"
                        value={diallingCode}
                        onChange={(value) => {
                          setDiallingCode(value);
                          setIsModified(true);
                        }}
                        style={{ width: "100%" }}
                        disabled={!country} // Disable when no country is selected
                      >
                        {/* {countryOptions[country]?.dialingCodes?.map((code) => ( */}
                        <Option
                          key={countryObject?.id}
                          value={countryObject?.dialing_code}
                        >
                          {countryObject?.dialing_code}
                        </Option>
                        {/* ))} */}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col xs={{ span: 24 }} lg={{ span: 8 }}>
                    <Form.Item
                      label="MSISDN"
                      required
                      tooltip={{
                        title: "This is a required field",
                        icon: <InfoCircleOutlined />,
                      }}
                    >
                      <Input
                        type="text"
                        placeholder="Enter your MSISDN"
                        value={msisdn}
                        onChange={(event) => {
                          setMsisdn(event.target.value);
                          setIsModified(true);
                        }}
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <div className="flex justify-end">
                  <Button
                    type="secondary"
                    className="bg-[blue] text-[white] ml-2"
                    htmlType="submit"
                    loading={loading}
                    onClick={updateAllFields}
                    disabled={!isModified}
                  >
                    Edit SIM Card
                  </Button>
                </div>
              </div>
            </Form>
          </Grid>
        </Grid>
      </List>
    </>
  );
};

export default EditSimCard;
