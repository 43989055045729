import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Alert,
  Typography,
  Form,
  message,
  Row,
  Col,
  Select,
  Card,
  Button,
  Modal,
  Empty,
  Radio,
  Layout,
  Grid,
  Spin,
} from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import AllApplications from "./applications";
import { downloadCSV } from "../user/csvDownloader";
import useLogout from "../useLogout";
const { Title } = Typography;
const { Option } = Select;
const { Content } = Layout;
const { useBreakpoint } = Grid;

const RunTest = () => {
  const logout = useLogout();
  const mnoAPI = process.env.REACT_APP_API_MobileOperators;
  const simApiUrl = process.env.REACT_APP_API_SIMForSpecialTesters;
  const checkIfNumsAssigned =
    "https://tywp44yzhi.execute-api.us-east-1.amazonaws.com/dev/sims_for_special_testers";
  const specialCountriesAPI =
    "https://0id4y5c0xb.execute-api.us-east-1.amazonaws.com/dev";

  const [messageApi, contextHolder] = message.useMessage();
  const [mnoNameList, setMNONameList] = useState([]);
  const [mnoObject, setMnoObject] = useState(null);
  const [numbers, setNumbers] = useState([]);
  const [isSpecialUser, setIsSpecialUser] = useState(false);
  const [phoneNumbers, setPhoneNumbers] = useState([]);
  const [selectedNumbers, setSelectedNumbers] = useState([]);
  const [showNumbersCard, setShowNumbersCard] = useState(false);
  const [numbersAssigned, setNumbersAssigned] = useState(false);
  const [lockNumbersModalVisible, setLockNumbersModalVisible] = useState(false);
  const [noNumbersAvailable, setNoNumbersAvailable] = useState(false);
  const [numbersLocked, setNumbersLocked] = useState(false);
  const [loadingNumbers, setLoadingNumbers] = useState(false);
  const [loadingCountries, setLoadingCountries] = useState(false);
  const [disabledCountries, setDisabledCountries] = useState({});
  const [simLimit, setSimLimit] = useState(null); // No default value for SIM limit
  const storedUser = localStorage.getItem("user");
  const user = JSON.parse(storedUser);
  const screens = useBreakpoint();

  // Hardcode country to Mali
  const country = "Mali";
  const countryId = 146;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${checkIfNumsAssigned}/${user.userId}`
        );

        if (response.data.inactive) {
          logout();
        } else if (response.data.body && response.data.body.length >= 100) {
          setNumbersAssigned(true);
          setPhoneNumbers(response.data.body);
          const phoneNumbers = response.data.body.map((item) => item.msisdn);
          setNumbers(phoneNumbers);
          if (user.userId === 219) {
            setNumbersLocked(true);
          }
        } else if (response.data.body && response.data.body.length === 50) {
          // Make call to the special countries API
          const specialCountriesResponse = await axios.get(
            `${specialCountriesAPI}/${user.userId}`
          );
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoadingCountries(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (user.userId === 219) {
      setIsSpecialUser(true);
    }
    const fetchData = async () => {
      try {
        setLoadingCountries(true);
        // Fetch MNO names with hardcoded countryId for Mali
        const dataUrl = `${mnoAPI}/?country=${countryId}`;
        const response = await axios.get(dataUrl);
        setMNONameList(response.data.body);
      } catch (error) {
        console.error("Error fetching MNO names:", error);
      } finally {
        setLoadingCountries(false);
      }
    };
    fetchData();
  }, []);

  const handleOperatorChange = (value) => {
    const selectedMNO = mnoNameList.find((mno) => mno.id === value);
    setMnoObject(selectedMNO);
    setShowNumbersCard(false); // Hide numbers card initially
  };

  const fetchPhoneNumbers = async (mnoId, simLimit) => {
    setLoadingNumbers(true);
    try {
      const response = await axios.get(
        `${simApiUrl}?mno=${mnoId}&tester_id=${user.userId}&sim_limit=${simLimit}`
      );
      // console.log("response from fetch number api", response.data.body);

      if (response.data.body.length === 0) {
        // console.log("empty body");
        setNoNumbersAvailable(true);
      } else {
        setNoNumbersAvailable(false);
      }

      const phoneNumbers = response.data.body.map(
        (phoneNumber) => phoneNumber.msisdn
      );
      setNumbers(phoneNumbers);

      const numbers = response.data.body.map((phoneNumber) => phoneNumber.id);
      setSelectedNumbers(numbers);
      setShowNumbersCard(true); // Show numbers card after numbers are fetched
    } catch (error) {
      console.error("Error fetching phone numbers:", error);
    } finally {
      setLoadingNumbers(false);
    }
  };

  const handleSimLimitChange = (e) => {
    const limit = e.target.value;
    setSimLimit(limit);
    fetchPhoneNumbers(mnoObject.id, limit); // Fetch phone numbers as soon as SIM limit is selected
  };

  const handleLockNumbersClick = async () => {
    try {
      if (!isSpecialUser && !disabledCountries[country]) {
        setDisabledCountries((prev) => ({ ...prev, [country]: true }));
        await axios.post(`${simApiUrl}?lock=true`, {
          user_id: user.userId,
          sims_id: selectedNumbers,
        });
        setNumbersLocked(true);
      }
      if (user.userId === 219) {
        downloadCSV(numbers);
      } else {
        setLockNumbersModalVisible(true);
      }
    } catch (error) {
      console.error("Error locking numbers:", error);
    }
  };

  const handleDownloadClick = () => {
    downloadCSV(numbers);
  };

  const handleSelectApplicationsClick = () => {
    setLockNumbersModalVisible(true);
  };

  const chunkArray = (arr, chunkSize) => {
    const chunks = [];
    for (let i = 0; i < arr.length; i += chunkSize) {
      chunks.push(arr.slice(i, i + chunkSize));
    }
    return chunks;
  };

  return (
    <Layout>
      {contextHolder}
      <Content
        style={{ padding: "0 18px", minHeight: "100vh", background: "#fff" }}
      >
        {numbersAssigned ? (
          <Card
            title="Numbers Assigned To You for Today"
            className="numbers-card"
            extra={
              <Button
                type="secondary"
                className="lock-button bg-[blue] text-[white]"
                size="small"
                onClick={
                  isSpecialUser && numbersLocked
                    ? handleDownloadClick
                    : handleSelectApplicationsClick
                }
                disabled={disabledCountries[country]}
              >
                {isSpecialUser && numbersLocked
                  ? "Download CSV"
                  : isSpecialUser
                  ? "Download CSV"
                  : "Select Applications"}
              </Button>
            }
          >
            <Row gutter={[16, 16]}>
              {numbers
                .reduce((resultArray, item, index) => {
                  const chunkIndex = Math.floor(index / 100);
                  if (!resultArray[chunkIndex]) {
                    resultArray[chunkIndex] = []; // start a new chunk
                  }
                  resultArray[chunkIndex].push(item);
                  return resultArray;
                }, [])
                .map((chunk, chunkIndex) => (
                  <Col key={chunkIndex} xs={24} sm={12} md={8} lg={4}>
                    <ul>
                      {chunk.map((number, index) => (
                        <li key={index}>{number}</li>
                      ))}
                    </ul>
                  </Col>
                ))}
            </Row>
          </Card>
        ) : (
          <>
            {!numbersAssigned && (
              <>
                {!isSpecialUser && (
                  <Alert
                    message="Alert: If you don't click on Lock Numbers button, the numbers will not be locked for you and you will not be able to see the generated OTPs. Therefore make sure to click on Lock Numbers."
                    type="error"
                    banner
                  />
                )}
                <Title level={4}>Numbers Assignment</Title>
                <Row gutter={16}>
                  <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                    <Form.Item
                      label="MNO Name"
                      required
                      tooltip={{
                        title: "This is a required field",
                        icon: <InfoCircleOutlined />,
                      }}
                    >
                      <Select
                        showSearch
                        placeholder="Select MNO"
                        optionFilterProp="children"
                        onChange={handleOperatorChange}
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        disabled={loadingCountries}
                      >
                        {mnoNameList.map((mno) => (
                          <Option key={mno.id} value={mno.id}>
                            {mno.name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                {mnoObject && (
                  <Row gutter={16}>
                    <Col xs={{ span: 24 }}>
                      <Form.Item
                        label="Number of SIMs"
                        required
                        tooltip={{
                          title: "This is a required field",
                          icon: <InfoCircleOutlined />,
                        }}
                      >
                        <Radio.Group
                          onChange={handleSimLimitChange}
                          value={simLimit}
                          disabled={showNumbersCard}
                        >
                          <Radio value={500}>500</Radio>
                          <Radio value={1000}>1000</Radio>
                        </Radio.Group>
                      </Form.Item>
                    </Col>
                  </Row>
                )}
                {loadingNumbers && (
                  <Spin tip="Loading phone numbers..." size="large" />
                )}
                {!loadingNumbers && showNumbersCard && (
                  <>
                    {noNumbersAvailable ? (
                      <Empty
                        description="No numbers available for the selected MNO and SIM limit"
                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                      />
                    ) : (
                      <>
                        {numbers.length > 0 && (
                          <>
                            {showNumbersCard && (
                              <>
                                {loadingNumbers ? (
                                  <Spin tip="Loading...">
                                    <Card
                                      title="Numbers Assigned To You for Today"
                                      className="numbers-card"
                                    />
                                  </Spin>
                                ) : (
                                  <Card
                                    title="Numbers Assigned To You for Today"
                                    className="numbers-card"
                                    extra={
                                      <Button
                                        type="secondary"
                                        className="lock-button bg-[blue] text-[white]"
                                        size="small"
                                        onClick={
                                          isSpecialUser && numbersLocked
                                            ? handleDownloadClick
                                            : handleLockNumbersClick
                                        }
                                        disabled={disabledCountries[country]}
                                      >
                                        {isSpecialUser && numbersLocked
                                          ? "Download CSV"
                                          : "Lock Numbers"}
                                      </Button>
                                    }
                                  >
                                    {noNumbersAvailable ? (
                                      <Empty
                                        description={
                                          <span>
                                            <strong>
                                              No numbers available for today.
                                            </strong>
                                          </span>
                                        }
                                      />
                                    ) : (
                                      <Row gutter={[16, 16]}>
                                        {numbers
                                          .reduce(
                                            (resultArray, item, index) => {
                                              const chunkIndex = Math.floor(
                                                index / 100
                                              );
                                              if (!resultArray[chunkIndex]) {
                                                resultArray[chunkIndex] = []; // start a new chunk
                                              }
                                              resultArray[chunkIndex].push(
                                                item
                                              );
                                              return resultArray;
                                            },
                                            []
                                          )
                                          .map((chunk, chunkIndex) => (
                                            <Col
                                              key={chunkIndex}
                                              xs={24}
                                              sm={12}
                                              md={8}
                                              lg={4}
                                            >
                                              <ul>
                                                {chunk.map((number, index) => (
                                                  <li key={index}>{number}</li>
                                                ))}
                                              </ul>
                                            </Col>
                                          ))}
                                      </Row>
                                    )}
                                  </Card>
                                )}
                              </>
                            )}
                            <br />
                          </>
                        )}
                      </>
                    )}
                  </>
                )}
              </>
            )}
          </>
        )}
      </Content>
      <Modal
        title="Select Applications"
        visible={lockNumbersModalVisible}
        width={"50%"}
        onCancel={() => setLockNumbersModalVisible(false)}
        footer={null}
      >
        <AllApplications numbers={numbers} />
      </Modal>
    </Layout>
  );
};

export default RunTest;
