import React from "react";
import {
  Dialog,
  DialogTitle,
  IconButton,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const SuspensionHistoryDialog = ({
  open,
  onClose,
  suspensionHistory,
  suspensionCount,
}) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      PaperProps={{
        style: {
          borderRadius: "12px",
          boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)",
        },
      }}
    >
      <div style={{ padding: "24px", position: "relative" }}>
        {/* Close button */}
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 16,
            top: 16,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>

        <DialogTitle sx={{ paddingBottom: "8px" }}>
          <Typography
            variant="h6"
            sx={{
              fontWeight: "bold",
              textAlign: "center",
            }}
          >
            Suspension History
          </Typography>
        </DialogTitle>

        {/* Suspension Count */}
        <Typography
          variant="body1"
          align="center"
          sx={{
            marginBottom: "16px",
            color: "#4a4a4a",
            fontWeight: 500,
            backgroundColor: "#f5f5f5",
            padding: "8px",
            borderRadius: "8px",
          }}
        >
          <strong>Total Suspensions: {suspensionCount}</strong>
        </Typography>

        {/* Suspension History Table */}
        <TableContainer
          component={Paper}
          sx={{
            borderRadius: "8px",
            boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
          }}
        >
          <Table>
            <TableHead>
              <TableRow
                sx={{
                  backgroundColor: "#f0f0f0",
                }}
              >
                <TableCell align="center">
                  <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                    #
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                    Inactivated At
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                    Activated At
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {suspensionHistory.map((record, index) => (
                <TableRow
                  key={index}
                  sx={{
                    "&:nth-of-type(odd)": {
                      backgroundColor: "#fafafa",
                    },
                    "&:hover": {
                      backgroundColor: "#f1f1f1",
                    },
                  }}
                >
                  <TableCell align="center">{index + 1}</TableCell>
                  <TableCell align="center">{record.inactivated_at}</TableCell>
                  <TableCell align="center">
                    {record.activated_at
                      ? record.activated_at
                      : "Still Suspended"}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        {/* Close button at the bottom */}
        {/* <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "20px",
          }}
        >
          <Button
            variant="contained"
            onClick={onClose}
            sx={{
              backgroundColor: "#4a90e2",
              ":hover": {
                backgroundColor: "#357ab7",
              },
              borderRadius: "20px",
              padding: "6px 24px",
            }}
          >
            Close
          </Button>
        </div> */}
      </div>
    </Dialog>
  );
};

export default SuspensionHistoryDialog;
