import React, { useState, useEffect, useRef } from "react";
import { Button, Input, Space, Table, message } from "antd";
import { SearchOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import CSVReader from "react-csv-reader";
import SuspensionHistoryDialog from "../superAdmin/suspensionHistoryModal";
import Typography from "@mui/material/Typography";
import Highlighter from "react-highlight-words";
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import ViewTesterDetails from "../tester/viewTesterDetails";
import EditTester from "../tester/editTester";
import {
  ExpandAltOutlined,
  UploadOutlined,
  DownloadOutlined,
} from "@ant-design/icons";
import AddTesterForm from "../tester/addTester";
import axios from "axios";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const apiUrl = process.env.REACT_APP_API_Testers;
const suspensionHistoryApiUrl = process.env.REACT_APP_SuspensionHistory;

const Testers = () => {
  const [messageApi, contextHolder] = message.useMessage();
  const [testers, setTesters] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [open, setOpen] = useState(false);
  const [testerId, setTesterId] = useState(null);
  const [searchedColumn, setSearchedColumn] = useState("");
  const [viewModalVisible, setviewModalVisible] = useState(false);
  const [editModalVisible, setEidtModalVisible] = useState(false);
  const [loading, setLoading] = useState(true);
  const [suspensionHistory, setSuspensionHistory] = useState([]);
  const [suspensionCount, setSuspensionCount] = useState(0);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const searchInput = useRef(null);
  const csvReaderRef = useRef(null);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const handleFile = async (data, fileInfo) => {
    const apiEndpoint = process.env.REACT_APP_BulkTestersEndPoint;

    const formattedData = data.map((item) => ({
      first_name: item.first_name,
      last_name: item.last_name,
      password: item.password,
      email: item.email,
      gender: item.gender,
      contact_number: item.contact_number,
      account_title: item.account_title,
      account_number: item.account_number,
      bank_name: item.bank_name,
      team_id: item.team_id,
      role_id: 4,
    }));

    try {
      const response = await axios.post(apiEndpoint, formattedData);

      if (response.data.body === "Bulk Records Uploaded") {
        message.success("Records uploaded successfully", 5);
      } else if (response.data.body.statusCode === 500) {
        const errorResponse = JSON.parse(response.data.body.body);
        message.error(
          `Error in uploading file: ${errorResponse.message} Details: ${errorResponse.error}`,
          5 // The message will stay for 5 seconds
        );
      }
    } catch (error) {
      console.error("Bulk import error:", error);
      message.error("Error in uploading file.", 5); // The message will stay for 5 seconds
    }
  };

  const handleDownloadSampleFile = () => {
    const sampleFileUrl = process.env.REACT_APP_BulkTestersUploadSampleFile;
    console.log("sampleFileUrl");
    const anchor = document.createElement("a");
    anchor.href = sampleFileUrl;
    anchor.download = "sample.csv";

    document.body.appendChild(anchor);
    anchor.click();

    // Cleanup
    document.body.removeChild(anchor);
  };

  useEffect(() => {
    fetchData();
  }, [setOpen, open]);

  const storedUser = localStorage.getItem("user");
  const user = JSON.parse(storedUser);

  const formatLastLogin = (timeString) => {
    // Extract numbers from the input string
    const matches = timeString.match(/\d+/g);

    if (!matches) {
      return "Invalid time format";
    }

    // Determine if days, hours, or minutes are provided
    let days = 0;
    let hours = 0;
    let minutes = 0;

    if (timeString.includes("day")) {
      days = matches[0] ? parseInt(matches[0], 10) : 0;
      if (timeString.includes("hour")) {
        hours = matches[1] ? parseInt(matches[1], 10) : 0;
      }
    } else if (timeString.includes("hour")) {
      hours = matches[0] ? parseInt(matches[0], 10) : 0;
      if (timeString.includes("minute")) {
        minutes = matches[1] ? parseInt(matches[1], 10) : 0;
      }
    } else if (timeString.includes("minute")) {
      minutes = matches[0] ? parseInt(matches[0], 10) : 0;
    }

    // Convert total hours into days
    const totalHours = hours + minutes / 60;
    days += Math.floor(totalHours / 24);
    hours = Math.floor(totalHours % 24);
    minutes = Math.floor(minutes % 60);

    // Convert days into years, months, and remaining days
    const daysInYear = 365.25;
    const daysInMonth = 30.44;

    const years = Math.floor(days / daysInYear);
    const remainingDaysAfterYears = days % daysInYear;
    const months = Math.floor(remainingDaysAfterYears / daysInMonth);
    const remainingDays = Math.floor(remainingDaysAfterYears % daysInMonth);

    let result = "";

    if (years > 0) {
      result += `${years} year${years > 1 ? "s" : ""} `;
    }

    if (months > 0) {
      result += `${months} month${months > 1 ? "s" : ""} `;
    }

    if (remainingDays > 0) {
      result += `${remainingDays} day${remainingDays > 1 ? "s" : ""} `;
    }

    if (hours > 0) {
      result += `${hours} hour${hours !== 1 ? "s" : ""} `;
    }

    if (minutes > 0) {
      result += `${minutes} minute${minutes !== 1 ? "s" : ""}`;
    }

    return result.trim() || "0 minutes";
  };
  const fetchData = async () => {
    try {
      const response = await fetch(apiUrl);
      if (!response.ok) {
        throw new Error("Failed to fetch testers");
      }
      const data = await response.json();
      const formattedData = data.body.map((tester) => ({
        ...tester,
        last_login: formatLastLogin(tester.last_login),
      }));
      setTesters(formattedData);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching testers:", error);
    }
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
            type="secondary"
            className="bg-[blue] text-[white]"
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1677ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const handleViewClick = (record) => {
    setviewModalVisible(true);
    setTesterId(record.id);
  };

  const handleEditClick = (record) => {
    setEidtModalVisible(true);
    setTesterId(record.id);
  };

  const handleAddTesterClick = () => {
    setOpen(true);
  };
  const handleSuspensionCountClick = async (record) => {
    try {
      const response = await fetch(`${suspensionHistoryApiUrl}/${record.id}`);
      const data = await response.json();
      const parsedData = JSON.parse(data.body);

      setSuspensionHistory(parsedData.history); // Set the history data
      setSuspensionCount(parsedData.history.length); // Set the suspension count
      setIsDialogOpen(true); // Open the dialog
    } catch (error) {
      console.error("Failed to fetch suspension history", error);
    }
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "first_name",
      key: "first_name",
      width: "12%",
      ...getColumnSearchProps("first_name"),
      sorter: (a, b) => a.first_name.localeCompare(b.first_name),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      width: "10%",
      ...getColumnSearchProps("email"),
      sorter: (a, b) => a.email.localeCompare(b.email),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Gender",
      dataIndex: "gender",
      key: "gender",
      width: "15%",
      ...getColumnSearchProps("gender"),
      sorter: (a, b) => a.gender.localeCompare(b.gender),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Suspension Count",
      dataIndex: "suspension_count",
      key: "suspension_count",
      width: "15%",
      ...getColumnSearchProps("suspension_count"),
      sorter: (a, b) => a.suspension_count - b.suspension_count,
      sortDirections: ["descend", "ascend"],
      render: (text, record) =>
        text > 0 ? (
          <Button
            type="link"
            onClick={() => handleSuspensionCountClick(record)}
            style={{ padding: 0, textAlign: "center", width: "100%" }}
          >
            {text}
          </Button>
        ) : (
          <span
            style={{
              padding: 0,
              textAlign: "center",
              display: "inline-block",
              width: "100%",
            }}
          >
            {text}
          </span>
        ),
    },
    {
      title: "Last Login",
      dataIndex: "last_login",
      key: "last_login",
      width: "15%",
      ...getColumnSearchProps("last_login"),
      sorter: (a, b) => a.last_login - b.last_login,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Actions",
      dataIndex: "view",
      key: "view",
      width: "20%",
      render: (text, record) => (
        <Space>
          <Button
            icon={<ExpandAltOutlined />}
            onClick={() => handleViewClick(record)}
          >
            View Details
          </Button>
          <Button
            icon={<EditOutlined />}
            onClick={() => handleEditClick(record)}
          >
            Edit
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <>
      {contextHolder}
      <div
        style={{ display: "flex", flexDirection: "column", marginBottom: 16 }}
      >
        <div style={{ display: "flex", alignItems: "center", marginBottom: 8 }}>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h5" component="div">
            All Testers
          </Typography>
          <Button
            icon={<PlusOutlined />}
            onClick={handleAddTesterClick}
            style={{ marginLeft: "auto" }}
          >
            Add Testers
          </Button>
        </div>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <div style={{ marginRight: "8px" }}>
            <CSVReader
              ref={csvReaderRef} // Assign the ref here
              onFileLoaded={handleFile}
              inputId="csvFile"
              inputStyle={{ display: "none" }}
              parserOptions={{ header: true, skipEmptyLines: true }}
            />

            <Button
              icon={<UploadOutlined />}
              onClick={() =>
                csvReaderRef.current && csvReaderRef.current.click()
              } // Ensure csvReaderRef.current is not null before accessing its click method
            >
              Upload CSV
            </Button>
          </div>
          <div>
            <Button
              icon={<DownloadOutlined />}
              onClick={handleDownloadSampleFile}
            >
              Download Sample CSV
            </Button>
          </div>
        </div>
      </div>
      <Table
        columns={columns}
        dataSource={testers}
        loading={loading}
        pagination={{
          showSizeChanger: true,
          defaultPageSize: 5,
          pageSizeOptions: ["5", "10", "20"],
        }}
        locale={{
          emptyText: loading ? "Loading..." : "No Data",
        }}
        scroll={{ x: "100%" }}
      />

      <Dialog
        fullScreen
        open={open}
        onClose={() => setOpen(false)}
        TransitionComponent={Transition}
        style={{ zIndex: 20 }}
      >
        <AddTesterForm setOpen={setOpen} />
      </Dialog>
      <Dialog
        fullScreen
        open={viewModalVisible}
        onClose={() => setviewModalVisible(false)}
        TransitionComponent={Transition}
        style={{ zIndex: 20 }}
      >
        <ViewTesterDetails setOpen={setviewModalVisible} testerId={testerId} />
      </Dialog>
      <Dialog
        fullScreen
        open={editModalVisible}
        onClose={() => setEidtModalVisible(false)}
        TransitionComponent={Transition}
        style={{ zIndex: 20 }}
      >
        <EditTester setOpen={setEidtModalVisible} id={testerId} />
      </Dialog>
      {/* Suspension History Modal */}
      <SuspensionHistoryDialog
        open={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        suspensionHistory={suspensionHistory}
        suspensionCount={suspensionCount}
      />
    </>
  );
};

export default Testers;
