import React, { useState, useEffect, useRef } from "react";
import { Button, Input, Space, Table, message, Modal } from "antd";
import {
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import Typography from "@mui/material/Typography";
import Highlighter from "react-highlight-words";
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import CSVReader from "react-csv-reader";
import { UploadOutlined, DownloadOutlined } from "@ant-design/icons";
import axios from "axios";
import AddApplication from "../applicationsManger/addApplication";
import ViewApplication from "../applicationsManger/viewApplication";
import EditApplication from "../applicationsManger/editApplication";
import { ExpandAltOutlined } from "@ant-design/icons";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const apiUrl = process.env.REACT_APP_API_Applications;

const Applications = () => {
  const [users, setUsers] = useState([]);
  const [messageApi, contextHolder] = message.useMessage();
  const [applications, setApplications] = useState([]);
  const [serialNumber, setSerialNumber] = useState(1);
  const [open, setOpen] = useState(false);
  const [appId, setAppId] = useState(null);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [viewModalVisible, setviewModalVisible] = useState(false);
  const [editModalVisible, setEidtModalVisible] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [loading, setLoading] = useState(true);
  const csvReaderRef = useRef(null);
  const searchInput = useRef(null);
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };
  const handleDownloadSampleFile = () => {
    const sampleFileUrl = process.env.REACT_APP_AppsBulkUploadSampleFile;
    const anchor = document.createElement("a");
    anchor.href = sampleFileUrl;
    anchor.download = "sample.csv";

    document.body.appendChild(anchor);
    anchor.click();

    // Cleanup
    document.body.removeChild(anchor);
  };

  const handleDeleteClick = (record) => {
    setSelectedRecord(record);
    setDeleteModalVisible(true);
  };
  const handleDeleteConfirm = async () => {
    // Make API call to delete the selected record
    try {
      const response = await fetch(`${apiUrl}/${selectedRecord.id}`, {
        method: "DELETE",
      });
      if (response.ok) {
        message.success("Record deleted successfully");
        setApplications(
          applications.filter((record) => record.id !== selectedRecord.id)
        );
        console.log("if");
      } else {
        message.error("Failed to delete record");
      }
    } catch (error) {
      console.error("Error deleting record:", error);
      message.error("Failed to delete record");
    }
    setDeleteModalVisible(false);
  };
  const handleDeleteCancel = () => {
    setDeleteModalVisible(false);
  };
  const handleAddClick = () => {
    setOpen(true);
  };

  const handleEditClick = async (record) => {
    setEidtModalVisible(true);
    setAppId(record.id);
  };

  const handleViewClick = async (record) => {
    setviewModalVisible(true);
    setAppId(record.id);
  };

  const handleFile = async (data, fileInfo) => {
    // Assuming the endpoint and the request payload structure
    const apiEndpoint =
      "https://arvuc8x0kj.execute-api.us-east-1.amazonaws.com/dev/bulk-apps";

    // Map the CSV data to API's response
    const formattedData = data.map((item) => ({
      app_type: item.app_type,
      name: item.name,
      visible: 1,
    }));

    // Send the bulk data to your API endpoint
    try {
      const response = await axios.post(apiEndpoint, formattedData);

      if (response.data.body === "Bulk Records Uploaded") {
        message.success("Records uploaded successfully");
      }
    } catch (error) {
      console.error("Bulk import error:", error);
      message.error("Error in uploading file.");
      return;
    }
  };

  useEffect(() => {
    fetchData();
  }, [
    setOpen,
    open,
    applications,
    setApplications,
    editModalVisible,
    setEidtModalVisible,
  ]);

  const fetchData = async () => {
    try {
      const response = await fetch(apiUrl);
      if (!response.ok) {
        throw new Error("Failed to fetch teams");
      }
      const data = await response.json();
      setUsers(data.body);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching teams:", error);
    }
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
            type="secondary"
            className="bg-[blue] text-[white]"
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1677ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const columns = [
    {
      title: "Serial No.",
      dataIndex: "id",
      key: "id",
      width: "10%",
      render: (text, record, index) => <span>{serialNumber + index}</span>,
    },
    {
      title: "Application Name",
      dataIndex: "name",
      key: "name",
      width: "20%",
      ...getColumnSearchProps("name"),
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "App Type",
      dataIndex: "app_type",
      key: "app_type",
      width: "12%",
      ...getColumnSearchProps("app_type"),
      sorter: (a, b) => a.app_type.localeCompare(b.app_type),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Actions",
      dataIndex: "view",
      key: "view",
      width: "20%",
      render: (text, record) => (
        <Space>
          <Button
            icon={<ExpandAltOutlined />}
            onClick={() => handleViewClick(record)}
          >
            View Details
          </Button>
          <Button
            icon={<EditOutlined />}
            onClick={() => handleEditClick(record)}
          >
            Edit
          </Button>
          <Button
            icon={<DeleteOutlined />}
            onClick={() => handleDeleteClick(record)}
            danger
          >
            Delete
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <>
      {contextHolder}
      <div
        style={{ display: "flex", flexDirection: "column", marginBottom: 16 }}
      >
        <div style={{ display: "flex", alignItems: "center", marginBottom: 8 }}>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h5" component="div">
            All Applications
          </Typography>
          <Button
            icon={<PlusOutlined />}
            onClick={handleAddClick}
            style={{ marginLeft: "auto" }}
          >
            Add Applications
          </Button>
        </div>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <div style={{ marginRight: "8px" }}>
            <CSVReader
              ref={csvReaderRef} // Assign the ref here
              onFileLoaded={handleFile}
              inputId="csvFile"
              inputStyle={{ display: "none" }}
              parserOptions={{ header: true, skipEmptyLines: true }}
            />

            <Button
              icon={<UploadOutlined />}
              onClick={() =>
                csvReaderRef.current && csvReaderRef.current.click()
              } // Ensure csvReaderRef.current is not null before accessing its click method
            >
              Upload CSV
            </Button>
          </div>
          <div>
            <Button
              icon={<DownloadOutlined />}
              onClick={handleDownloadSampleFile}
            >
              Download Sample CSV
            </Button>
          </div>
        </div>
      </div>

      <Table
        columns={columns}
        dataSource={users}
        loading={loading}
        locale={{
          emptyText: loading ? "Loading..." : "No Data",
        }}
        pagination={{
          showSizeChanger: true,
          defaultPageSize: 5,
          pageSizeOptions: ["5", "10", "20"],
        }}
        scroll={{ x: "100%" }}
      />
      <Modal
        title="Confirm Delete"
        visible={deleteModalVisible}
        onOk={handleDeleteConfirm}
        onCancel={handleDeleteCancel}
        okButtonProps={{ style: { backgroundColor: "red" } }}
      >
        Are you sure you want to delete this record?
      </Modal>
      <Dialog
        fullScreen
        open={open}
        onClose={() => setOpen(false)}
        TransitionComponent={Transition}
        style={{ zIndex: 20 }}
      >
        <AddApplication setOpen={setOpen} />
      </Dialog>
      <Dialog
        fullScreen
        open={viewModalVisible}
        onClose={() => setviewModalVisible(false)}
        TransitionComponent={Transition}
        PaperProps={{
          style: {
            width: "700px",
            maxWidth: "800px",
            height: "70vh",
            maxHeight: "90vh",
            overflow: "auto",
          },
        }}
        style={{ zIndex: 20 }}
      >
        <ViewApplication setOpen={setviewModalVisible} appId={appId} />
      </Dialog>
      <Dialog
        fullScreen
        open={editModalVisible}
        onClose={() => setEidtModalVisible(false)}
        TransitionComponent={Transition}
        PaperProps={{
          style: {
            width: "700px",
            maxWidth: "800px",
            height: "70vh",
            maxHeight: "90vh",
            overflow: "auto",
          },
        }}
        style={{ zIndex: 20 }}
      >
        <EditApplication setOpen={setEidtModalVisible} id={appId} />
      </Dialog>
    </>
  );
};

export default Applications;
