import React, { useState, useEffect, useRef } from "react";
import { Button, Input, Space, Table, message } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import SuspensionHistoryDialog from "../superAdmin/suspensionHistoryModal";

const apiUrl = process.env.REACT_APP_API_Teamlead_Testers;
const toggleUrl = process.env.REACT_APP_AccountStatusToggle;
const suspensionHistoryApiUrl = process.env.REACT_APP_SuspensionHistory;

const Testers = () => {
  const [messageApi, contextHolder] = message.useMessage();
  const [testers, setTesters] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [suspensionHistory, setSuspensionHistory] = useState([]);
  const [suspensionCount, setSuspensionCount] = useState(0);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loadingId, setLoadingId] = useState(null); // Track loading state for individual user toggles
  const searchInput = useRef(null);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleToggleStatus = async (id, currentStatus) => {
    try {
      if (!toggleUrl) {
        throw new Error("Toggle URL is not defined");
      }

      // Set loadingId to indicate the button is in a loading state
      setLoadingId(id);

      const updatedStatus = currentStatus === 0 ? 1 : 0;
      const url = `${toggleUrl}`;

      const response = await fetch(url, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ user_id: id }),
      });

      if (!response.ok) {
        throw new Error("Failed to toggle status");
      }

      fetchData();
      messageApi.success(
        `User ${
          updatedStatus === 0 ? "activated" : "inactivated"
        } successfully!`
      );
    } catch (error) {
      console.error("Error toggling status:", error);
      messageApi.error("Failed to toggle status");
    } finally {
      // Reset loadingId after the operation is complete
      setLoadingId(null);
    }
  };
  const calculateRemainingHours = (inactivatedAt) => {
    const currentTime = new Date(); // Get current time
    const inactivatedTime = new Date(inactivatedAt); // Parse 'inactivated_at' into Date object

    const timeDifferenceInMs = currentTime - inactivatedTime; // Difference in milliseconds
    const hoursPassed = timeDifferenceInMs / (1000 * 60 * 60); // Convert milliseconds to hours

    const remainingHours = 72 - hoursPassed; // Calculate remaining hours
    return remainingHours > 0 ? Math.floor(remainingHours) : 0; // Ensure non-negative result
  };

  const handleSuspensionCountClick = async (record) => {
    try {
      const response = await fetch(`${suspensionHistoryApiUrl}/${record.id}`);
      const data = await response.json();
      const parsedData = JSON.parse(data.body);

      setSuspensionHistory(parsedData.history); // Set the history data
      setSuspensionCount(parsedData.history.length); // Set the suspension count
      setIsDialogOpen(true); // Open the dialog
    } catch (error) {
      console.error("Failed to fetch suspension history", error);
    }
  };

  const fetchData = async () => {
    try {
      const storedUser = localStorage.getItem("user");
      const user = JSON.parse(storedUser);

      const response = await fetch(`${apiUrl}/${user.userId}`);
      if (!response.ok) {
        throw new Error("Failed to fetch testers");
      }
      const data = await response.json();
      setTesters(data.body);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching testers:", error);
    }
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
            type="secondary"
            className="bg-[blue] text-[white]"
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button type="link" size="small" onClick={() => close()}>
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1677ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const columns = [
    {
      title: "Name",
      dataIndex: "first_name",
      key: "first_name",
      width: "12%",
      ...getColumnSearchProps("first_name"),
      sorter: (a, b) => a.first_name.localeCompare(b.first_name),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      width: "10%",
      ...getColumnSearchProps("email"),
      sorter: (a, b) => a.email.localeCompare(b.email),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Gender",
      dataIndex: "gender",
      key: "gender",
      width: "15%",
      ...getColumnSearchProps("gender"),
      sorter: (a, b) => a.gender.localeCompare(b.gender),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Last Login",
      dataIndex: "last_login",
      key: "last_login",
      width: "15%",
      ...getColumnSearchProps("last_login"),
      sorter: (a, b) => a.last_login - b.last_login,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Suspension Count",
      dataIndex: "suspension_count",
      key: "suspension_count",
      width: "15%",
      ...getColumnSearchProps("suspension_count"),
      sorter: (a, b) => a.suspension_count - b.suspension_count,
      sortDirections: ["descend", "ascend"],
      render: (text, record) =>
        text > 0 ? (
          <Button
            type="link"
            onClick={() => handleSuspensionCountClick(record)}
            style={{ padding: 0, textAlign: "center", width: "100%" }}
          >
            {text}
          </Button>
        ) : (
          <span
            style={{
              padding: 0,
              textAlign: "center",
              display: "inline-block",
              width: "100%",
            }}
          >
            {text}
          </span>
        ),
    },
    {
      title: "Inactivated at",
      dataIndex: "inactivated_at",
      key: "inactivated_at",
      width: "15%",
      ...getColumnSearchProps("inactivated_at"),
      sorter: (a, b) => a.inactivated_at - b.inactivated_at,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Remaining Time",
      dataIndex: "inactivated_at",
      key: "remaining_time",
      width: "15%",
      render: (inactivatedAt) => {
        const remainingHours = calculateRemainingHours(inactivatedAt); // Calculate remaining hours
        return `${remainingHours} hour${remainingHours !== 1 ? "s" : ""} left`;
      },
    },
    {
      title: "Status",
      key: "status",
      width: "15%",
      render: (_, record) => (
        <Button
          type={record.inactive === 0 ? "danger" : "primary"}
          style={{
            borderColor: record.inactive === 0 ? "red" : "#1677ff",
            color: record.inactive === 0 ? "#fff" : "#fff",
            backgroundColor: record.inactive === 0 ? "red" : "#1677ff",
          }}
          onClick={() => handleToggleStatus(record.id, record.inactive)}
          disabled={loadingId === record.id} // Disable the button if it's the one being toggled
          loading={loadingId === record.id} // Show loading spinner if the status is being toggled
        >
          {record.inactive === 0 ? "Inactivate" : "Activate"}
        </Button>
      ),
    },
  ];

  return (
    <>
      {contextHolder}
      {testers && (
        <>
          <Table
            columns={columns}
            dataSource={testers}
            loading={loading}
            locale={{
              emptyText: loading ? "Loading..." : "No Data",
            }}
            pagination={{
              showSizeChanger: true,
              defaultPageSize: 5,
              pageSizeOptions: ["5", "10", "20"],
            }}
            scroll={{ x: "100%" }}
          />
        </>
      )}
      <SuspensionHistoryDialog
        open={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        suspensionHistory={suspensionHistory}
        suspensionCount={suspensionCount}
      />
    </>
  );
};

export default Testers;
