import React, { useState, useEffect, useRef } from "react";
import { Button, Input, Space, Table, message, Modal } from "antd";
import {
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
  SearchOutlined,
  DownloadOutlined,
  UploadOutlined,
  ExpandAltOutlined,
} from "@ant-design/icons";
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import Typography from "@mui/material/Typography";
import Highlighter from "react-highlight-words";
import CSVReader from "react-csv-reader";
import axios from "axios";
import AddSimcard from "./addSimcard";
import EditSimCard from "./editSimcard";
import ViewSimCardDetails from "./viewSimCard";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const simAPI = process.env.REACT_APP_API_SIMCards;

const SimCardTable = ({ nav }) => {
  const [searchText, setSearchText] = useState("");
  const [simcardData, setSimcardData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedSimId, setSelectedSimId] = useState(null);
  const [searchedColumn, setSearchedColumn] = useState("");
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [viewModalVisible, setviewModalVisible] = useState(false);
  const [editModalVisible, setEidtModalVisible] = useState(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [selectedSimCards, setSelectedSimCards] = useState(new Set());
  const [loadingData, setLoadingData] = useState(true);
  const [showBatchDeleteConfirmation, setShowBatchDeleteConfirmation] =
    useState(false);
  const csvReaderRef = useRef(null);
  const searchInput = useRef(null);
  const filteredSimcards = simcardData.filter((simcard) => {
    const fullName = `${simcard.first_name || ""} ${simcard.last_name || ""}`;
    const operator = simcard.operator || "";
    const country_name = simcard.country_name || "";
    const prefix = simcard.prefix ? simcard.prefix.toString() : "";
    const msisdn = simcard.msisdn ? simcard.msisdn.toString() : "";
    const iccid = simcard.iccid ? simcard.iccid.toString() : "";
    const imsi = simcard.imsi ? simcard.imsi.toString() : "";

    return (
      fullName.toLowerCase().includes(searchQuery.toLowerCase()) ||
      operator.toLowerCase().includes(searchQuery.toLowerCase()) ||
      country_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      prefix.includes(searchQuery.toLowerCase()) ||
      msisdn.includes(searchQuery.toLowerCase()) ||
      iccid.includes(searchQuery.toLowerCase()) ||
      imsi.includes(searchQuery.toLowerCase())
    );
  });
  const handleDeleteClick = async (record) => {
    setDeleteModalVisible(true);
    setSelectedRecord(record.key);
  };
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const handleConfirmDelete = async () => {
    if (showBatchDeleteConfirmation) {
      try {
        const batchDeleteApi = process.env.REACT_APP_API_BatchDeleteSimCards;
        await axios.delete(batchDeleteApi, {
          ids: Array.from(selectedSimCards),
        });

        setSimcardData((prevData) =>
          prevData.filter((simcard) => !selectedSimCards.has(simcard.key))
        );
        setLoadingData(false);
        setSelectedSimCards(new Set()); // Clear selection after deletion
      } catch (error) {
        console.error("Error during batch deletion:", error);
      }
    } else {
      try {
        // Single deletion logic as before
        await axios.delete(`${simAPI}/${selectedRecord}`);
        message.success("Record deleted successfully");
        setSimcardData((prevData) =>
          prevData.filter((simcard) => simcard.key !== selectedRecord)
        );
        setLoadingData(false);
      } catch (error) {
        console.error("Error deleting sim:", error);
        message.error("Failed to delete record");
      }
      setDeleteModalVisible(false);
    }

    setShowDeleteConfirmation(false);
    setShowBatchDeleteConfirmation(false);
  };

  const handleEditClick = (id) => {
    setEidtModalVisible(true);
    setSelectedSimId(id);
  };

  const handleViewClick = (id) => {
    console.log("id", id);
    setviewModalVisible(true);
    setSelectedSimId(id);
  };

  const handleDownloadSampleFile = () => {
    const sampleFileUrl = process.env.REACT_APP_SIMSBulkUploadSampleFile;
    const anchor = document.createElement("a");
    anchor.href = sampleFileUrl;
    anchor.download = "sample.csv";

    document.body.appendChild(anchor);
    anchor.click();

    // Cleanup
    document.body.removeChild(anchor);
  };

  const handleAddClick = () => {
    setOpen(true);
  };

  const [csvUploaded, setCsvUploaded] = useState(false);

  const handleFile = async (data, fileInfo) => {
    const apiEndpoint = process.env.REACT_APP_SIMBulkUploadEndpoint;
    const formattedData = data.map((item) => ({
      mno_id: item.mno_id,
      msisdn: item.msisdn,
    }));

    try {
      const response = await axios.post(apiEndpoint, formattedData);

      if (response.data.body == "Bulk Records Uploaded") {
        message.success("Records uploaded successfully");
        setCsvUploaded(true); // Set the flag to true after successful upload
      }
    } catch (error) {
      console.error("Bulk import error:", error);
      message.error("Error in uploading file.");
      return;
    }
  };

  useEffect(() => {
    if (csvUploaded) {
      fetchSimcardData(); // Fetch data only if CSV has been uploaded
      setCsvUploaded(false); // Reset the flag after fetching data
    }
  }, [csvUploaded]);

  const toggleSimCardSelection = (simCardId) => {
    // Implement toggle selection logic here
  };
  const handleDeleteCancel = () => {
    setDeleteModalVisible(false);
  };
  const toggleSelectAll = () => {
    if (selectedSimCards.size === filteredSimcards.length) {
      setSelectedSimCards(new Set());
    } else {
      setSelectedSimCards(
        new Set(filteredSimcards.map((simcard) => simcard.key))
      );
    }
  };
  useEffect(() => {
    fetchSimcardData();
  }, [open, editModalVisible]);

  const fetchSimcardData = async () => {
    setLoading(true);
    try {
      fetch(simAPI)
        .then((res) => res.json())
        .then((res) => {
          const newData = res.body.map((item) => ({
            key: item.id,

            country_name: item.country_name,
            mnoName: item.mnoName,
            number: item.number,
            countryObject: item.countryObject,
            operator: item.operator_name,
            prefix: item.dialing_code,
            msisdn: item.msisdn,
          }));

          setSimcardData(newData);
          setLoadingData(false);
        });
    } catch (error) {
      console.error("Error fetching testers:", error);
    } finally {
      setLoading(false);
    }
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
            type="secondary"
            className="bg-[blue] text-[white]"
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1677ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const columns = [
    {
      title: "Serial No.",
      dataIndex: "id",
      key: "id",
      render: (text, record, index) => <span>{index + 1}</span>,
    },
    {
      title: "Country",
      dataIndex: "country_name",
      key: "country_name",
      ...getColumnSearchProps("country_name"),
    },
    {
      title: "Operator",
      dataIndex: "operator",
      key: "operator",
      ...getColumnSearchProps("operator"),
    },
    {
      title: "Prefix",
      dataIndex: "prefix",
      key: "prefix",
      ...getColumnSearchProps("prefix"),
    },
    {
      title: "MSISDN",
      dataIndex: "msisdn",
      key: "msisdn",
      ...getColumnSearchProps("msisdn"),
    },

    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      render: (text, record) => (
        <Space>
          <Button
            icon={<ExpandAltOutlined />}
            onClick={() => handleViewClick(record.key)}
          >
            View Details
          </Button>
          <Button
            icon={<EditOutlined />}
            onClick={() => handleEditClick(record.key)}
          >
            Edit
          </Button>
          {/* <Button
            icon={<DeleteOutlined />}
            onClick={() => handleDeleteClick(record)}
            danger
          >
            Delete
          </Button> */}
        </Space>
      ),
    },
  ];

  return (
    <>
      <div
        style={{ display: "flex", flexDirection: "column", marginBottom: 16 }}
      >
        <div style={{ display: "flex", alignItems: "center", marginBottom: 8 }}>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h5" component="div">
            All Sim Cards
          </Typography>
          <Button
            icon={<PlusOutlined />}
            onClick={handleAddClick}
            style={{ marginLeft: "auto" }}
          >
            Add Sim Card
          </Button>
        </div>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <div style={{ marginRight: "8px" }}>
            <CSVReader
              ref={csvReaderRef} // Assign the ref here
              onFileLoaded={handleFile}
              inputId="csvFile"
              inputStyle={{ display: "none" }}
              parserOptions={{ header: true, skipEmptyLines: true }}
            />

            <Button
              icon={<UploadOutlined />}
              onClick={() =>
                csvReaderRef.current && csvReaderRef.current.click()
              } // Ensure csvReaderRef.current is not null before accessing its click method
            >
              Upload CSV
            </Button>
          </div>
          <div>
            <Button
              icon={<DownloadOutlined />}
              onClick={handleDownloadSampleFile}
            >
              Download Sample CSV
            </Button>
          </div>
        </div>
      </div>
      <Table
        columns={columns}
        dataSource={filteredSimcards}
        loading={loadingData}
        locale={{
          emptyText: loadingData ? "Loading..." : "No Data",
        }}
        pagination={{
          showSizeChanger: true,
          defaultPageSize: 5,
          pageSizeOptions: ["5", "10", "20"],
        }}
        scroll={{ x: "100%" }}
      />
      <Modal
        title="Confirm Delete"
        visible={deleteModalVisible}
        onOk={handleConfirmDelete}
        onCancel={handleDeleteCancel}
        okButtonProps={{ style: { backgroundColor: "red" } }}
      >
        Are you sure you want to delete this record?
      </Modal>
      <Dialog
        fullScreen
        open={open}
        onClose={() => setOpen(false)}
        TransitionComponent={Transition}
        style={{ zIndex: 20 }}
      >
        <AddSimcard setOpen={setOpen} />
      </Dialog>
      <Dialog
        fullScreen
        open={viewModalVisible}
        onClose={() => setviewModalVisible(false)}
        TransitionComponent={Transition}
        style={{ zIndex: 20 }}
      >
        <ViewSimCardDetails setOpen={setviewModalVisible} id={selectedSimId} />
      </Dialog>
      <Dialog
        fullScreen
        open={editModalVisible}
        onClose={() => setEidtModalVisible(false)}
        TransitionComponent={Transition}
        style={{ zIndex: 20 }}
      >
        <EditSimCard setOpen={setEidtModalVisible} id={selectedSimId} />
      </Dialog>
    </>
  );
};

export default SimCardTable;
