import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { useRole } from "../RoleContext";

const Login = () => {
  const signinAPI = process.env.REACT_APP_API_Signin;
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState("");
  const { setRoleId } = useRole();

  function generateSessionId() {
    return Math.random().toString(36).substring(2) + Date.now().toString(36);
  }

  useEffect(() => {
    const setupLogoutAtSpecificTime = () => {
      const now = new Date();
      const targetTime = new Date();
      targetTime.setHours(12, 57, 0, 0); // Set target time to 3:00 AM

      // If target time is in the past, set it to the next day
      if (now > targetTime) {
        targetTime.setDate(targetTime.getDate() + 1);
      }

      const timeUntilTarget = targetTime - now;

      const timer = setTimeout(() => {
        localStorage.removeItem("setupTime");
        localStorage.removeItem("user");
        navigate("/login");
        window.location.reload();
      }, timeUntilTarget);

      return () => clearTimeout(timer);
    };

    const user = JSON.parse(localStorage.getItem("user"));
    if (user) {
      setupLogoutAtSpecificTime();
    }
  }, [navigate]);

  const handleLogin = async (e) => {
    const sessionId = Date.now().toString(); // For simplicity, using timestamp as ID

    // Set session ID in localStorage
    localStorage.setItem("session_id", sessionId);

    // Store the login status for this session (optional, to track login state)
    localStorage.setItem("isLoggedIn", "true");
    e.preventDefault();
    if (!email || !password) {
      setError("Please enter email and password");
    } else {
      try {
        const response = await fetch(signinAPI, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email, password }),
        });
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();

        const roleId = data.body.roleId;
        localStorage.setItem("roleId", roleId);
        // Check if the returned data.body is a string and contains the suspension message
        if (
          typeof data.body === "string" &&
          data.body.startsWith("Your account has been suspended")
        ) {
          setError(data.body); // Display the suspension message
        } else if (roleId === 1) {
          navigate("/sa");
        } else if (roleId === 2) {
          navigate("/ad");
        } else if (roleId === 3) {
          navigate("/teamleadDashboard");
        } else if (roleId === 4 || roleId === 6) {
          navigate("/testerDashboard");
        } else if (roleId === 5) {
          navigate("/SpecialUserDashboard");
        } else if (roleId === 7) {
          navigate("/SpecialTesterDashboard");
        } else if (roleId === 8) {
          navigate("/SpecialTeamLeadDashboard");
        } else {
          setError("Invalid email or password");
        }

        // Save user data and setup time in localStorage
        localStorage.setItem("setupTime", new Date().getTime());
        localStorage.setItem("user", JSON.stringify(data.body));
        window.location.reload();
      } catch (error) {
        console.error("Error:", error);
        setError("An error occurred while signing in");
      }
    }
  };
  return (
    <div className="min-h-screen flex justify-center items-center bg-[#e5e7eb]">
      <div className="p-6 bg-white rounded-2xl w-full max-w-md flex flex-col items-center">
        <h3 className="font-semibold text-3xl text-[#013CC6]">
          EON Testing Tool
        </h3>
        <p className="text-gray mt-2">
          Welcome back! Please login to your account.
        </p>
        <form onSubmit={handleLogin} className="w-full mt-4">
          <label className="block mb-2 text-gray-700">Email</label>
          <input
            className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-green-400"
            type="email"
            placeholder="mail@gmail.com"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <div className="relative mt-4">
            <label className="block mb-2 text-gray-700">
              Password
              <input
                type={showPassword ? "text" : "password"}
                className="w-full px-4 py-2 border font-normal border-gray-300 rounded-lg focus:outline-none focus:border-green-400"
                placeholder="Enter Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <div
                className="absolute inset-y-0 right-0 top-5 flex items-center pr-4 cursor-pointer"
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? (
                  <AiOutlineEyeInvisible size={23} className="text-gray" />
                ) : (
                  <AiOutlineEye size={25} className="text-gray" />
                )}
              </div>
            </label>
            {error && <p className="text-red-600 text-xs mt-1">{error}</p>}
          </div>
          <button
            type="submit"
            className="w-full mt-4  bg-[#013CC6] hover:bg-[blue-500] text-[white] p-3 rounded-full font-semibold shadow-lg transition duration-500"
          >
            Sign in
          </button>
        </form>
        <div className="mt-5 text-center text-gray-400 text-xs">
          <span>
            Copyright © 2024{" "}
            <a
              href="https://codepen.io/uidesignhub"
              rel="noopener noreferrer"
              target="_blank"
              title="Ajimon"
              className="text-green hover:text-green-500"
            >
              EON
            </a>
          </span>
        </div>
      </div>
    </div>
  );
};

export default Login;
