import React, { useState, useEffect, useMemo } from "react";
import axios from "axios";
import { Bar } from "react-chartjs-2";
import { useTable } from "react-table";
import styled from "styled-components";
import { TailSpin } from "react-loader-spinner";
import "chart.js/auto";

// Flexbox container to hold both graphs and tables
const GraphTableContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 15px;
  @media (max-width: 900px) {
    flex-direction: column;
  }
`;

// Container for individual graphs
const GraphContainer = styled.div`
  flex-basis: 49%;
  margin-bottom: 15px;
  padding: 20px;
  background: #ffffff;
  border-radius: 10px; /* Slightly more rounded corners for a modern look */
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); /* Softer shadow for a sleeker appearance */
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 400px; /* Ensure it takes the full height */
  @media (max-width: 900px) {
    width: 100%;
    height: auto; /* Adjust height for smaller screens */
  }
`;

// Container to ensure the chart takes all available space
const ChartContainer = styled.div`
  flex: 1; /* Ensure it takes all available space */
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

// Container for the table
const TableContainer = styled.div`
  flex-basis: 49%;
  margin-top: 0px;
  background: #ffffff;
  border-radius: 10px; /* More rounded corners */
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); /* Softer shadow */
  overflow-x: auto;
  height: 400px; /* Set fixed height */
  @media (max-width: 900px) {
    width: 100%;
    height: auto; /* Adjust height for smaller screens */
    margin-top: 0;
  }
`;

// Header container to hold the title and date pickers
const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  margin-bottom: 20px;
  flex-wrap: wrap;

  @media (max-width: 600px) {
    flex-direction: column;
  }
`;

// Title styling
const Title = styled.h1`
  font-size: 24px;
  font-weight: bold;
  color: #343a40;
  margin: 0;
  @media (max-width: 600px) {
    font-size: 20px;
    margin-bottom: 10px;
  }
`;

// Container for date pickers and buttons
const DatePickerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  @media (max-width: 600px) {
    flex-direction: column;
  }
`;

// Fetch button styling
const FetchButton = styled.button`
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-left: 10px;

  &:hover {
    background-color: #0056b3;
  }

  &:focus {
    outline: none;
  }

  @media (max-width: 600px) {
    width: 100%;
    margin-left: 0;
    padding: 10px;
    margin-top: 10px;
  }
`;

// Chart type selector styling
const ChartTypeSelector = styled.select`
  margin-left: 10px;
  padding: 8px 12px;
  font-size: 14px;
  border: 1px solid #ced4da;
  border-radius: 4px;

  @media (max-width: 600px) {
    width: 100%;
    margin-top: 10px;
  }
`;

// Loading spinner container
const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%; /* Adjust height to fill the container */
`;

// Table styling
const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  height: 100%; /* Adjust height to fill the container */
`;

// Table header styling
const StyledThead = styled.thead`
  background-color: #007bff;
  color: white;
`;

// Table header cell styling
const StyledTh = styled.th`
  padding: 10px;
  border: 1px solid #ddd;
  text-align: left;
`;

// Table row styling
const StyledTr = styled.tr`
  &:nth-child(even) {
    background-color: #f2f2f2;
  }
  &:hover {
    background-color: #ddd;
  }
`;

// Table data cell styling
const StyledTd = styled.td`
  padding: 10px;
  border: 1px solid #ddd;
  text-align: left;
`;

// Card container styling
const CardContainer = styled.div`
  flex-basis: 49%; /* Ensure cards are side by side */
  padding: 20px;
  background-color: #f8f9fa;
  border-radius: 10px; /* More rounded corners */
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); /* Softer shadow */
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  color: #343a40;
  margin-bottom: 15px; /* Spacing between cards */
  line-height: 1.5; /* Added line-height for better spacing */

  @media (max-width: 900px) {
    width: 100%;
  }
`;

// Flex container for the cards
const CardsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  @media (max-width: 900px) {
    flex-direction: column;
  }
`;

const TesterProfile = ({ id }) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [chartType, setChartType] = useState("bar");

  // Fetching data logic
  const fetchData = async () => {
    try {
      const response = await axios.get(
        `https://vq2bw3pbdi.execute-api.us-east-1.amazonaws.com/dev/${id}`
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching data", error);
      return null;
    }
  };

  useEffect(() => {
    const updateData = async () => {
      setLoading(true);
      const newData = await fetchData();
      setData(newData);
      setLoading(false);
    };
    updateData();
  }, [id]);

  // Current month filter logic
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth(); // 0-11 (Jan = 0, Dec = 11)

  // Mapping months to labels
  const monthLabels = [
    // "January",
    // "February",
    // "March",
    // "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    // "October",
    // "November",
    // "December",
  ];

  // Filter data till the month before the current one
  const filteredLabels = monthLabels.slice(0, currentMonth); // Only include months up to the previous month

  const otpCounts = data?.monthly_otp_count || {};
  const payments = data?.payments || {};

  // OTP and Payment data till the last month
  const otpData = filteredLabels.map(
    (label, index) => otpCounts[monthLabels[index].toLowerCase()] || 0
  );
  const paymentData = filteredLabels.map(
    (label, index) =>
      payments[`payment_${monthLabels[index].toLowerCase()}`] || 0
  );

  // Chart data now contains two datasets (one for OTP counts and one for Payments)
  const chartData = {
    labels: filteredLabels,
    datasets: [
      {
        label: "OTP Counts",
        data: otpData,
        backgroundColor: "rgba(153, 102, 255, 0.6)",
        borderColor: "rgba(153, 102, 255, 1)",
        borderWidth: 1,
      },
      {
        label: "Payments (Rs.)",
        data: paymentData,
        backgroundColor: "rgba(75, 192, 192, 0.6)",
        borderColor: "rgba(75, 192, 192, 1)",
        borderWidth: 1,
      },
    ],
  };

  // Table data
  const tableData = useMemo(
    () =>
      filteredLabels.map((label, index) => ({
        month: label,
        otpCount: otpData[index],
        payment: paymentData[index],
      })),
    [filteredLabels, otpData, paymentData]
  );

  const columns = useMemo(
    () => [
      { Header: "Month", accessor: "month" },
      { Header: "OTP Count", accessor: "otpCount" },
      {
        Header: "Payment (Rs.)",
        accessor: "payment",
        Cell: ({ value }) => `Rs. ${parseFloat(value).toFixed(2)}`,
      },
    ],
    []
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data: tableData });

  // Loading and rendering logic
  if (loading) {
    return (
      <LoadingContainer>
        <TailSpin height="80" width="80" color="#007bff" ariaLabel="loading" />
      </LoadingContainer>
    );
  }

  if (!data) {
    return (
      <LoadingContainer>
        <TailSpin height="80" width="80" color="#007bff" ariaLabel="loading" />
      </LoadingContainer>
    );
  }

  return (
    <div className=" md:flex-row justify-center items-center md:items-start px-20 space-y-4 md:space-y-0 md:space-x-4">
      <GraphTableContainer>
        <GraphContainer>
          <ChartContainer>
            <Bar
              data={chartData}
              options={{
                responsive: true,
                maintainAspectRatio: false,
                plugins: { legend: { position: "top" } },
              }}
            />
          </ChartContainer>
        </GraphContainer>

        <TableContainer>
          <StyledTable {...getTableProps()}>
            <StyledThead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <StyledTh {...column.getHeaderProps()}>
                      {column.render("Header")}
                    </StyledTh>
                  ))}
                </tr>
              ))}
            </StyledThead>
            <tbody {...getTableBodyProps()}>
              {rows.map((row) => {
                prepareRow(row);
                return (
                  <StyledTr {...row.getRowProps()}>
                    {row.cells.map((cell) => (
                      <StyledTd {...cell.getCellProps()}>
                        {cell.render("Cell")}
                      </StyledTd>
                    ))}
                  </StyledTr>
                );
              })}
            </tbody>
          </StyledTable>
        </TableContainer>
      </GraphTableContainer>
    </div>
  );
};

export default TesterProfile;
