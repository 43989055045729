import React, { useState, useEffect } from "react";
import axios from "axios";
import { Input, Select, Radio, Button, Form, message, Col } from "antd";
import AppBar from "@mui/material/AppBar";
import List from "@mui/material/List";
import Grid from "@mui/material/Grid";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";

const EditTesterForm = ({ setOpen, id }) => {
  const apiUrl = process.env.REACT_APP_API_Testers;
  const teamsURL = process.env.REACT_APP_API_Team;

  const [form] = Form.useForm();
  const [teams, setTeams] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchTeams();
  }, []);

  const fetchTeams = async () => {
    setLoading(true);
    try {
      const response = await axios.get(teamsURL);
      setTeams(response.data.body);
    } catch (error) {
      console.error("Error fetching teams:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchTesterData = async (id) => {
    try {
      const response = await axios.get(`${apiUrl}/${id}`);
      const testerData = response.data.body;

      // Determine the tester type based on role_id
      let testerType;
      if (testerData.role_id === 4) {
        testerType = "tester";
      } else if (testerData.role_id === 6) {
        testerType = "internalteam";
      } else if (testerData.role_id === 7) {
        testerType = "specialtester";
      }

      form.setFieldsValue({
        first_name: testerData.first_name,
        last_name: testerData.last_name,
        email: testerData.email,
        gender: testerData.gender,
        contact_number: testerData.contact_number,
        password: testerData.password,
        confirmPassword: testerData.password,
        account_title: testerData.account_title,
        account_number: testerData.account_number,
        bank_name: testerData.bank_name,
        team: testerData.team_name,
        role_id: testerData.role_id,
        type: testerType, // Set tester type based on role_id
      });
    } catch (error) {
      console.error("Error fetching tester data:", error);
    }
  };

  useEffect(() => {
    if (id) {
      fetchTesterData(id);
    }
  }, [id]);

  const handleSubmit = async (values) => {
    try {
      const team_id = teams.find((team) => team.name === values.team)?.id;
      const payload = {
        ...values,
        role_id: values.type === "specialtester" ? 7 : 4,
        team_id,
      };

      // Make the primary API call
      const response = await axios.put(`${apiUrl}/${id}`, payload);

      // If role_id is 7, make an additional API call
      if (payload.role_id === 7) {
        const additionalApiResponse = await axios.put(
          `https://urt77bdoq5.execute-api.us-east-1.amazonaws.com/dev/special-testers/${id}`,
          payload
        );

        // You can check the additional response status if needed
        if (additionalApiResponse.status !== 200) {
          throw new Error(
            "Failed to make additional API call for Special Tester"
          );
        }
      }

      // Proceed with the rest of the flow as is
      if (response.status === 200) {
        setOpen(false);
      } else {
        throw new Error("Failed to edit tester");
      }
    } catch (error) {
      console.error("Error editing tester:", error);
      message.error("Failed to edit tester");
    }
  };

  return (
    <>
      <AppBar sx={{ position: "relative" }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={() => setOpen(false)}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            Edit Tester
          </Typography>
        </Toolbar>
      </AppBar>
      <List style={{ height: "100vh" }}>
        <Grid
          container
          style={{ height: "100%", justifyContent: "center" }}
          alignItems="center"
        >
          <Grid item xs={12} sm={8} md={6} lg={8}>
            <Form
              form={form}
              onFinish={handleSubmit}
              className="mx-10"
              initialValues={{
                gender: "male", // set default gender if needed
              }}
            >
              <div className=" mb-4">
                <h2 className="text-2xl font-bold">Edit Tester</h2>
              </div>
              <div className="grid grid-cols-1 gap-4">
                <Form.Item
                  name="type"
                  rules={[
                    { required: true, message: "Please select Tester Type" },
                  ]}
                >
                  <Select placeholder="Select Tester Type" disabled>
                    <Select.Option value="tester">Tester</Select.Option>
                    <Select.Option value="internalteam">
                      Internal Team
                    </Select.Option>
                    <Select.Option value="specialtester">
                      Special Tester
                    </Select.Option>
                  </Select>
                </Form.Item>

                <Form.Item
                  name="first_name"
                  rules={[
                    { required: true, message: "Please enter First Name" },
                  ]}
                >
                  <Input
                    placeholder="Enter First Name"
                    addonBefore="First Name"
                  />
                </Form.Item>
                <Form.Item
                  name="last_name"
                  rules={[
                    { required: true, message: "Please enter Last Name" },
                  ]}
                >
                  <Input
                    placeholder="Enter Last Name"
                    addonBefore="Last Name"
                  />
                </Form.Item>
                <Form.Item
                  name="email"
                  rules={[
                    { required: true, message: "Please enter Email" },
                    { type: "email", message: "Please enter a valid email" },
                  ]}
                >
                  <Input placeholder="Enter Email" addonBefore="Email" />
                </Form.Item>
                <Form.Item
                  name="contact_number"
                  rules={[
                    {
                      required: true,
                      message: "Please enter Contact Number",
                    },
                    {
                      pattern: /^(?:\+?92|0)\d{10}$/,
                      message: "Please enter a valid contact number",
                    },
                  ]}
                >
                  <Input
                    placeholder="Enter Contact Number"
                    addonBefore="Contact Number"
                  />
                </Form.Item>
                <Col xs={24} sm={12}>
                  <Form.Item
                    name="password"
                    rules={[
                      { required: true, message: "Please enter Password" },
                      {
                        min: 6,
                        message: "Password must be at least 6 characters long",
                      },
                    ]}
                  >
                    <Input.Password
                      placeholder="Enter Password"
                      addonBefore="Password"
                      iconRender={(visible) =>
                        visible ? <AiOutlineEyeInvisible /> : <AiOutlineEye />
                      }
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item
                    name="confirmPassword"
                    dependencies={["password"]}
                    rules={[
                      {
                        required: true,
                        message: "Please confirm your password",
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || getFieldValue("password") === value) {
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            new Error("Passwords do not match")
                          );
                        },
                      }),
                    ]}
                  >
                    <Input.Password
                      placeholder="Confirm Password"
                      addonBefore="Confirm Password"
                      iconRender={(visible) =>
                        visible ? <AiOutlineEyeInvisible /> : <AiOutlineEye />
                      }
                    />
                  </Form.Item>
                </Col>
                <Form.Item name="gender">
                  <Radio.Group>
                    <Radio value="male">Male</Radio>
                    <Radio value="female">Female</Radio>
                  </Radio.Group>
                </Form.Item>
                <Form.Item
                  name="team"
                  rules={[{ required: true, message: "Please select Team" }]}
                >
                  <Select placeholder="Select Team">
                    {teams.map((team) => (
                      <Select.Option key={team.id} value={team.name}>
                        {team.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item name="bank_name">
                  <Input
                    placeholder="Enter Bank Name"
                    addonBefore="Bank Name"
                  />
                </Form.Item>
                <Form.Item name="account_title">
                  <Input
                    placeholder="Enter Account Title"
                    addonBefore="Account Title"
                  />
                </Form.Item>
                <Form.Item name="account_number">
                  <Input
                    placeholder="Enter Account Number"
                    addonBefore="Account Number"
                  />
                </Form.Item>
              </div>
              <Form.Item>
                <div className="flex justify-end">
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{ backgroundColor: " #1890ff" }}
                  >
                    Edit Tester
                  </Button>
                </div>
              </Form.Item>
            </Form>
          </Grid>
        </Grid>
      </List>
    </>
  );
};

export default EditTesterForm;
