import React, { useEffect, useState } from "react";
import {
  Button,
  Layout,
  Menu,
  Dropdown,
  Modal,
  Form,
  Input,
  theme,
  Tabs,
  Grid,
} from "antd";
import axios from "axios";

import Stats from "../stats";
import SimCards from "../simcardManager/simcards";
import Testerstatistics from "../admin/testerStatistics";
import Applications from "../applicationsManger/applications";
import Teamleads from "../teamleads/teamLeads";
import Testers from "../tester/testers";
import Teams from "../team/team";
import { LogoutOutlined, SettingOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

const { Header, Content, Footer } = Layout;
const { Item } = Form;
const { useBreakpoint } = Grid;
const updatePassword = process.env.REACT_APP_API_UpdatePassword;
const TeamleadDashboard = () => {
  const [selectedMenuItem, setSelectedMenuItem] = useState("1");
  const [changePasswordVisible, setChangePasswordVisible] = useState(false);
  const [form] = Form.useForm();
  const user = JSON.parse(localStorage.getItem("user")) || {};
  const navigate = useNavigate();
  const screens = useBreakpoint();
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();
  const onLogout = () => {
    localStorage.removeItem("setupTime");
    localStorage.removeItem("user");
    navigate("/");
    window.location.reload();
  };

  const handleMenuSelect = (key) => {
    setSelectedMenuItem(key);
  };

  const handlePasswordChange = () => {
    setChangePasswordVisible(true);
  };

  const handleCancel = () => {
    setChangePasswordVisible(false);
  };

  const handleFinish = async (values) => {
    try {
      const { currentPassword, newPassword } = values;
      const response = await axios.put(updatePassword, {
        userId: user.userId,
        oldPassword: currentPassword,
        newPassword: newPassword,
      });

      if (response.status === 200) {
        setChangePasswordVisible(false);
      } else {
        console.error("Password change failed");
      }
    } catch (error) {
      console.error("Error changing password:", error);
    }
  };

  const menu = (
    <Menu>
      <Menu.Item
        key="changePassword"
        icon={<SettingOutlined />}
        onClick={handlePasswordChange}
      >
        Change Password
      </Menu.Item>
      <Menu.Item key="logout" onClick={onLogout} icon={<LogoutOutlined />}>
        Logout
      </Menu.Item>
    </Menu>
  );

  return (
    <Layout>
      <Header
        style={{
          position: "sticky",
          top: 0,
          zIndex: 1,
          width: "100%",
          display: "flex",
          alignItems: "center",
        }}
      >
        <div className="demo-logo" />
        <Menu
          theme="dark"
          mode="horizontal"
          defaultSelectedKeys={["1"]}
          items={items}
          onClick={({ key }) => handleMenuSelect(key)}
          style={{
            flex: 1,
            minWidth: 0,
          }}
        />
        <Dropdown overlay={menu} trigger={["click"]}>
          <Button type="link">
            {user.name
              .split(" ")
              .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
              .join(" ")}
          </Button>
        </Dropdown>
      </Header>
      <Content
        style={{
          padding: screens.xs ? "0 14px" : "0 48px",
        }}
      >
        <div
          style={{
            padding: screens.xs ? 3 : 24,
            minHeight: 380,
            background: colorBgContainer,
            borderRadius: borderRadiusLG,
          }}
        >
          <View1 />
        </div>
      </Content>

      <Footer
        style={{
          textAlign: "center",
        }}
      >
        EON Testing Tool ©{new Date().getFullYear()} Created by EON Intelligence
      </Footer>

      {/* Change Password Modal */}
      <Modal
        title="Change Password"
        visible={changePasswordVisible}
        onCancel={handleCancel}
        footer={null}
      >
        <Form
          form={form}
          name="changePasswordForm"
          onFinish={handleFinish}
          layout="vertical"
        >
          <Item
            name="currentPassword"
            label="Current Password"
            rules={[
              {
                required: true,
                message: "Please input your current password!",
              },
            ]}
          >
            <Input.Password />
          </Item>
          <Item
            name="newPassword"
            label="New Password"
            rules={[
              {
                required: true,
                message: "Please input your new password!",
              },
            ]}
          >
            <Input.Password />
          </Item>
          <Item
            name="confirmNewPassword"
            label="Confirm New Password"
            dependencies={["newPassword"]}
            rules={[
              {
                required: true,
                message: "Please confirm your new password!",
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("newPassword") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error("The two passwords do not match!")
                  );
                },
              }),
            ]}
          >
            <Input.Password />
          </Item>
          <Item>
            <Button
              type="secondary"
              className="bg-[blue] text-[white]"
              htmlType="submit"
            >
              Change Password
            </Button>
          </Item>
        </Form>
      </Modal>
    </Layout>
  );
};

const View1 = () => {
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();
  const onChangeTab = (key) => {
    setSelectedMenuTabItem(key);
  };
  const [tabPosition, setTabPosition] = useState("left");
  const [selectedMenuTabItem, setSelectedMenuTabItem] = useState("1");
  useEffect(() => {
    const handleResize = () => {
      // Switch to horizontal tabs for small screens
      if (window.innerWidth <= 768) {
        setTabPosition("top");
      } else {
        // Default to vertical tabs for larger screens
        setTabPosition("left");
      }
    };

    // Initial setup
    handleResize();

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Remove event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div
      style={{
        padding: 24,
        minHeight: 380,
        background: colorBgContainer,
        borderRadius: borderRadiusLG,
      }}
    >
      <Tabs
        activeKey={selectedMenuTabItem}
        onChange={onChangeTab}
        type="card"
        tabPosition={tabPosition}
        style={{ minHeight: 200 }}
      >
        <Tabs.TabPane key="1" tab="Teams Manager">
          <Teams />
        </Tabs.TabPane>
        <Tabs.TabPane key="2" tab="Testers Manager">
          <Testers />
        </Tabs.TabPane>
        <Tabs.TabPane key="3" tab="Team Leads Manager">
          <Teamleads />
        </Tabs.TabPane>
        <Tabs.TabPane key="4" tab="Sim Cards Manager">
          <SimCards />
        </Tabs.TabPane>
        <Tabs.TabPane key="5" tab="Applications Manager">
          <Applications />
        </Tabs.TabPane>
        <Tabs.TabPane key="6" tab="OTP Statistics">
          <Stats />
        </Tabs.TabPane>
        <Tabs.TabPane key="7" tab="Tester Statistics">
          <Testerstatistics />
        </Tabs.TabPane>
      </Tabs>
    </div>
  );
};

const items = [
  {
    key: "1",
    label: `EON Testing Tool`,
    component: View1,
  },
];

export default TeamleadDashboard;
