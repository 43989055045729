// LeaderBoard.js
import React, { useState, useEffect, useRef, useMemo } from "react";
import { Button, Input, Space, Table, Typography } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";

const apiUrl =
  "https://jqh0cp25wl.execute-api.us-east-1.amazonaws.com/dev/special-testers-stats-for-team-lead";

const LeaderBoard = () => {
  const { Title } = Typography;
  const [searchText, setSearchText] = useState("");
  const [users, setUsers] = useState([]);
  const [sortOrder, setSortOrder] = useState("desc");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [loading, setLoading] = useState(true);
  const searchInput = useRef(null);

  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);
  const formattedDate = yesterday.toLocaleDateString("en-GB");

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await fetch(apiUrl);
      if (!response.ok) {
        throw new Error("Failed to fetch testers");
      }
      const data = await response.json();
      setUsers(data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching testers:", error);
    }
  };

  const sortedUsers = useMemo(() => {
    return [...users].sort((a, b) => {
      if (sortOrder === "desc") {
        return b.otps_count - a.otps_count;
      } else {
        return a.otps_count - b.otps_count;
      }
    });
  }, [users, sortOrder]);

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
            type="secondary"
            className="bg-[blue] text-[white]"
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1677ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const columns = [
    {
      title: "Ranking",
      dataIndex: "ranking",
      key: "ranking",
      width: "12%",
      render: (_, __, index) => index + 1,
    },
    {
      title: "Name",
      dataIndex: "first_name",
      key: "first_name",
      width: "12%",
      render: (_, user) => `${user.first_name} ${user.last_name}`,
      ...getColumnSearchProps("first_name"),
      sorter: (a, b) => a.first_name.localeCompare(b.first_name),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "OTPs Generated",
      dataIndex: "current_month_otps_count",
      key: "current_month_otps_count",
      width: "15%",
      ...getColumnSearchProps("current_month_otps_count"),
      sorter: (a, b) =>
        (a.current_month_otps_count || 0) - (b.current_month_otps_count || 0),
      sortDirections: ["descend", "ascend"],
      defaultSortOrder: "descend",
    },
  ];

  return (
    <>
      <Title level={3}>Leader Board </Title>
      <Table
        columns={columns}
        dataSource={sortedUsers}
        loading={loading}
        locale={{
          emptyText: loading ? "Loading..." : "No Data",
        }}
        pagination={{
          showSizeChanger: true,
          defaultPageSize: 5,
          pageSizeOptions: ["5", "10", "20"],
        }}
        scroll={{ x: "100%" }}
      />
    </>
  );
};

export default LeaderBoard;
