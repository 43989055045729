// roleContext.js
import React, { createContext, useContext, useState, useEffect } from "react";

const RoleContext = createContext();

// roleContext.js
export const RoleProvider = ({ children }) => {
  const [roleId, setRoleId] = useState(() => {
    // Initialize roleId from local storage, if available
    return localStorage.getItem("roleId");
  });

  // Update local storage whenever roleId changes
  useEffect(() => {
    if (roleId) {
      localStorage.setItem("roleId", roleId);
    }
  }, [roleId]);

  return (
    <RoleContext.Provider value={{ roleId, setRoleId }}>
      {children}
    </RoleContext.Provider>
  );
};

export const useRole = () => useContext(RoleContext);
