import "./App.css";
import Login from "./components/login";
import Logout from "./components/logout";
import AddTester from "./components/team/addTeam";
import TeamLeadDashboard from "./components/teamleadDashboard";
import SuperAdminDashboard from "./components/superAdmin/superAdminDashboard";
import TesterDashboard from "./components/user/testerDashboard";
import ProjectHeadDashboard from "./components/admin/dashboard";
import SpecialUserDashboard from "./components/specialTester/specialTesterDashboard";
import SpecialTeamleadDashboard from "./components/specialTeamleadDashboard";
import SpecialTesterDashboard from "./components/internalSpecialTesters/specialTesterDashboard";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useEffect, useState } from "react";
import { useRole } from "./RoleContext";

function App() {
  const [isSessionExpired, setIsSessionExpired] = useState(false);

  const { roleId } = useRole();
  const [sessionId, setSessionId] = useState(
    localStorage.getItem("session_id")
  );

  useEffect(() => {
    // Listen for the 'storage' event
    const handleStorageChange = (event) => {
      if (event.key === "logout") {
        // Perform logout actions
        sessionStorage.clear();
        localStorage.clear();
        window.location.href = "/login"; // Redirect to login page
      }
    };

    // Add event listener for storage change
    window.addEventListener("storage", handleStorageChange);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  useEffect(() => {
    // Function to log out the user
    const logout = () => {
      // Clear session details
      localStorage.removeItem("session_id");
      localStorage.removeItem("isLoggedIn");
      localStorage.removeItem("setupTime");
      localStorage.removeItem("user");
      window.location.href = "/login";
      window.location.reload();
    };

    // Listen for changes in localStorage
    const handleStorageChange = () => {
      const currentSessionId = localStorage.getItem("session_id");
      if (currentSessionId !== sessionId) {
        logout(); // Log out if the session ID does not match
      }
    };

    // Set up the event listener
    window.addEventListener("storage", handleStorageChange);

    return () => {
      // Clean up the event listener on unmount
      window.removeEventListener("storage", handleStorageChange);
    };
  }, [sessionId]);

  useEffect(() => {
    const checkSession = () => {
      const setupTime = localStorage.getItem("setupTime");
      if (setupTime) {
        const setupTimeMs = parseInt(setupTime, 10);
        const currentTime = Date.now();
        // 86400000 ms = 24 hours
        if (currentTime - setupTimeMs > 86400000) {
          setIsSessionExpired(true);
        }
      }
    };

    checkSession();
    const intervalId = setInterval(checkSession, 1000);

    return () => clearInterval(intervalId);
  }, []);

  const isUserLoggedIn = !!localStorage.getItem("user");

  return (
    <Router>
      <Routes>
        {isUserLoggedIn ? (
          isSessionExpired ? (
            <Route path="*" element={<Logout />} />
          ) : (
            <>
              {/* Role-based routes */}
              {roleId == 1 && (
                <Route path="/sa" element={<SuperAdminDashboard />} />
              )}
              {roleId == 2 && (
                <Route path="/ad" element={<ProjectHeadDashboard />} />
              )}
              {roleId == 3 && (
                <Route
                  path="/teamleadDashboard"
                  element={<TeamLeadDashboard />}
                />
              )}
              {roleId == 4 && (
                <Route path="/testerDashboard" element={<TesterDashboard />} />
              )}
              {roleId == 5 && (
                <Route
                  path="/SpecialUserDashboard"
                  element={<SpecialUserDashboard />}
                />
              )}
              {roleId == 8 && (
                <Route
                  path="/SpecialTeamleadDashboard"
                  element={<SpecialTeamleadDashboard />}
                />
              )}
              {roleId == 7 && (
                <Route
                  path="/SpecialTesterDashboard"
                  element={<SpecialTesterDashboard />}
                />
              )}

              {/* Shared or default routes */}
              <Route path="/add-testers" element={<AddTester />} />
              <Route path="*" element={<Logout />} />
            </>
          )
        ) : (
          <>
            <Route path="/" element={<Login />} />
            <Route path="*" element={<Login />} />
          </>
        )}
      </Routes>
    </Router>
  );
}

export default App;
